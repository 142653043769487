<template>
  <div>
    <div v-show="!NoleftArrow">
      <van-nav-bar :title="title" left-arrow @click-left="_onBackTap" fixed z-index="99" placeholder/>
    </div>
    <div v-show="NoleftArrow" style="height: 1.33333rem;">
      <van-nav-bar :title="title" fixed z-index="99" placeholder/>
    </div>
  </div>
</template>

<script>
import {NavBar} from 'vant';

export default {
  components: {
    [NavBar.name]: NavBar,
  },
  name: "bh-navBar",
  props: {
    //左侧按钮是否需要返回
    NoleftArrow: {
      type: Boolean,
      value: false,
    },
    //是否重写返回按钮
    backProxy: {
      type: Boolean,
      value: false,
    },
    //标题
    title: {
      type: String,
      default: ''
    },
    //返回多级页面，并传入几级
    returnPageNumber: {
      type: String,
      value: ''
    },
    //返回路径
    returnPage: {
      type: String,
      value: ''
    },
    //返回路径 参数
    params: {
      type: Object,
      value: ''
    },
  },
  data() {
    return {}
  },
  methods: {
    /**
     * 返回的点击事件
     */
    _onBackTap() {
      var returnPageNumber = this.returnPageNumber;
      var returnPage = this.returnPage;
      var params = this.params;

      if (!this.backProxy) {
        if (returnPageNumber != '' && returnPageNumber != undefined) {
          this.$router.go(parseInt(returnPageNumber));
          return
        }
        if (returnPage != "" && returnPage != undefined) {
          if (params == "" || params == undefined) {
            this.$router.replace({name: returnPage})
          } else {
            this.$router.replace({name: returnPage, params: params});
          }
          return;
        }
        this.$router.go(-1);
        return
      }
      this.$emit('NaviBack')
    },
  }
}
</script>

<style lang="less" scoped>

</style>
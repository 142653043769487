<template>
  <div class="clue-approve-detail">
    <bhNavBar title="审批详情"></bhNavBar>
    <div>
      <div class="title">转移理由</div>
      <div class="review">
        <van-row class="vanRowStyle" type="flex" justify="space-around">
          <van-col span="24">
            <span class="normalFont">{{ clueDetail.applyRemark }}</span>
          </van-col>
        </van-row>
      </div>
      <div class="title">线索基本信息</div>
      <div class="review">
        <van-row class="vanRowStyle" type="flex" justify="space-around">
          <van-col span="24">
            <span class="boldFont">公司定级：</span>
            <span class="normalFont">{{ getDict(clueDetail.companyRanking) }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">姓名：</span>
            <span class="normalFont">{{ clueDetail.clueName }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">职位：</span>
            <span class="normalFont">{{ clueDetail.position }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">部门：</span>
            <span class="normalFont">{{ clueDetail.deptName }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">行业：</span>
            <span class="normalFont">{{ getDict(clueDetail.industry) }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">手机号：</span>
            <span class="normalFont">{{ clueDetail.phoneNum }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">微信号：</span>
            <span class="normalFont">{{ clueDetail.wechatNum }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">线索来源：</span>
            <span class="normalFont">{{ getDict(clueDetail.sourceFrom) }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">城市：</span>
            <span class="normalFont">{{ clueDetail.city }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">公司名称：</span>
            <span class="normalFont">{{ clueDetail.companyName }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">公司简称：</span>
            <span class="normalFont">{{ clueDetail.companyAbbName }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">公司类别：</span>
            <span class="normalFont">{{ getDict(clueDetail.companyType) }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">主负责人：</span>
            <span class="normalFont">{{ getUserName(clueDetail.principalUserId) }}</span>
          </van-col>
          <van-col span="12">
            <span class="boldFont">次负责人：</span>
            <span class="normalFont">{{ getUserName(clueDetail.secondUserId) }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">邮箱：</span>
            <span class="normalFont">{{ clueDetail.emailNum }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">网址：</span>
            <span class="normalFont">{{ clueDetail.companyUrl }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">最佳联系时间：</span>
            <span class="normalFont">{{ clueDetail.bestContact }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">详细地址：</span>
            <span class="normalFont">{{ clueDetail.detailsAddress }}</span>
          </van-col>
        </van-row>
      </div>
      <div class="title">企业价值</div>
      <div class="review">
        <van-row class="vanRowStyle" type="flex" justify="space-around">
          <van-col span="24">
            <span class="boldFont">企业产品：</span>
            <span class="normalFont">{{ clueDetail.enterpriseProducts }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">服务与支持：</span>
            <span class="normalFont">{{ clueDetail.serviceSupport }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">解决方案：</span>
            <span class="normalFont">{{ clueDetail.solution }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">风险/机遇分析：</span>
            <span class="normalFont">{{ clueDetail.opportunityAnalysis }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">优势/劣势分析：</span>
            <span class="normalFont">{{ clueDetail.weaknessesAnalysis }}</span>
          </van-col>
        </van-row>
      </div>
      <template v-if="clueDetail.crmCluePosts && clueDetail.crmCluePosts.length > 0">
        <div class="title">企业岗位</div>
        <div class="position-tag">校招岗</div>
        <template v-for="(item, index) in clueDetail.crmCluePosts">
          <div class="review" v-if="item.postType == 1" :key="index">
            <van-row  class="vanRowStyle" type="flex" justify="space-around">
              <van-col span="24">
                <span class="boldFont">岗位名称：</span>
                <span class="normalFont">{{ item.postName }}</span>
              </van-col>
              <van-col span="24">
                <span class="boldFont">面试城市：</span>
                <span class="normalFont">{{ item.interviewCity }}</span>
              </van-col>
              <van-col span="24">
                <span class="boldFont">工作城市：</span>
                <span class="normalFont">{{ item.workCity }}</span>
              </van-col>
              <van-col span="24">
                <span class="boldFont">岗位描述：</span>
                <span class="normalFont">{{ item.jobDescription }}</span>
              </van-col>
              <van-col span="24">
                <span class="boldFont">任职要求：</span>
                <span class="normalFont">{{ item.jobRequirements }}</span>
              </van-col>
            </van-row>
          </div>
        </template>
        <div class="position-tag">社招岗</div>
        <template v-for="(sitem, sindex) in clueDetail.crmCluePosts">
          <div class="review" v-if="sitem.postType == 2" :key="sindex">
            <van-row  class="vanRowStyle" type="flex" justify="space-around">
              <van-col span="24">
                <span class="boldFont">岗位名称：</span>
                <span class="normalFont">{{ sitem.postName }}</span>
              </van-col>
              <van-col span="24">
                <span class="boldFont">面试城市：</span>
                <span class="normalFont">{{ sitem.interviewCity }}</span>
              </van-col>
              <van-col span="24">
                <span class="boldFont">工作城市：</span>
                <span class="normalFont">{{ sitem.workCity }}</span>
              </van-col>
              <van-col span="24">
                <span class="boldFont">岗位描述：</span>
                <span class="normalFont">{{ sitem.jobDescription }}</span>
              </van-col>
              <van-col span="24">
                <span class="boldFont">任职要求：</span>
                <span class="normalFont">{{ sitem.jobRequirements }}</span>
              </van-col>
            </van-row>
          </div>
        </template>
      </template>
      <div class="title">其他信息</div>
      <div class="review">
        <van-row class="vanRowStyle" type="flex" justify="space-around">
          <van-col span="24">
            <span class="boldFont">企业介绍：</span>
            <span class="normalFont">{{ clueDetail.enterpriseIntroduction }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">合作注意事项：</span>
            <span class="normalFont">{{ clueDetail.cooperationConsiderations }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">客户要求：</span>
            <span class="normalFont">{{ clueDetail.customerRequirements }}</span>
          </van-col>
          <van-col span="24">
            <span class="boldFont">其他说明：</span>
            <span class="normalFont">{{ clueDetail.otherInstructions }}</span>
          </van-col>
        </van-row>
      </div>
     </div>
  </div>
</template>

<script>
import bhNavBar from "@/components/bright/bh-navBar/navBar"
import { Row, Col } from 'vant'
import { initClueDetail } from "@/getData/getData";
import { localStorageGet } from "@/libs/util"
import { DICTIONARY, ALLUSERDICTIONARY } from "@/getData/mutation-types";

import {responseUtil} from "@/libs/brightUtil";

export default {
  name: "clueApproveDetail",
  components: {
    bhNavBar,
    [Row.name]: Row,
    [Col.name]: Col
  },
  data() {
    return {
      clueDetail: {}
    }
  },
  watch: {
    $route: {
      handler(to) {
        this.getDetails(to.query.id)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getUserName(userId) {
      if (!userId) {
        return '未知'
      }
      if (typeof userId !== 'string') {
        userId = userId.toString()
      }
      const userList = localStorageGet(ALLUSERDICTIONARY)
      const arrUserIds = userId.split(',')
      let consultantNames = ''
      arrUserIds.forEach((item, index) => {
        const filterArr = userList.filter((user) => user.userId === item)
        if (filterArr.length > 0) {
          consultantNames += index === arrUserIds.length - 1 ? filterArr[0].username : filterArr[0].username + ','
        }
      })
      if (consultantNames !== '') {
        return consultantNames
      } else {
        return '未知'
      }
    },
    getDict(code) {
      if (code) {
        const firstCode = code.substring(0, 3)
        const DICT = localStorageGet(DICTIONARY)
        const arr = DICT[firstCode]
        if (arr) {
          const filterArr = arr.filter((item) => item.code === code)
          if (filterArr.length > 0) {
            return arr.filter((item) => item.code === code)[0]['label']
          }
        }
      }
      return code
    },
    getDetails(id) {
      var that = this
      initClueDetail(id).then(function (res) {
        // eslint-disable-next-line no-console
        console.log(res)
        responseUtil.dealResponse(that, res, () => {
          that.clueDetail = res.data
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
.clue-approve-detail {
  .title {
    font-size: 12px;
    margin: 15px 15px 5px 15px;
    color: #6D00FB;
    font-weight: bold;
    text-align: left;
    font-family: PingFangSC-Semibold;
  }
  .position-tag {
    font-size: 12px;
    margin: 15px 15px 5px 15px;
    background-color: #EDF5FF;
    border-radius: 3px;
    padding: 5px 14px;
    text-align: center;
  }
  .review {
    margin: 0 15px 15px 15px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 0.2rem;
    .vanRowStyle {
      width: 95%;
      margin: auto;
      .boldFont {
        font-size: 14px;
        font-weight: bold;
      }
      .normalFont {
        font-size: 13px;
        font-weight: normal;
        color: #999;
      }
    }
  }
  .bottom-Site{
    width: 100%;
    height: 60px;
  }
  .bottom {
    width: 100%;
    height: 50px;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 0px;
    background-color: rgba(248, 248, 248, 1);
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
  }
}
</style>